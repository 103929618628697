import React, { useEffect } from "react";
import Header from "./header";
import { useLocation } from "react-router-dom";
import Sidebar from "./components/sidebar";

const Layout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (["/camera"].includes(location.pathname)) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [location.pathname]);

  return (
    <div className="w-full min-h-screen h-full m-auto bg-gradient-to-r from-[#F08800] to-[#F55B00]">
      {location.pathname === "/" && <Header />}
      <Sidebar />
      {children}
    </div>
  );
};

export default React.memo(Layout);
