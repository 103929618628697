import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Context } from "./context";
import { toast } from "react-toastify";
import { get, post } from "./axios";
import Loader from "./components/loader";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import _ from "lodash";
import { Checkbox } from "primereact/checkbox";
import { ClearIcon, PasteIcon } from "./components/Icons";

const Give = () => {
  const inputRef0 = useRef();
  const inputRef1 = useRef();
  const [track, setTrack] = useState("");
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [phone, setPhone] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [phones, setPhones] = useState([]);
  const [phone_items, setPhone_items] = useState([]);
  const [pshow, setPshow] = useState(false);

  const check_track = async (code) => {
    if (code) {
      try {
        setLoading(true);
        const res = await get(`/cargo/check/${code?.trim()}`);
        if (res?.success) {
          if (res?.data?.length > 0) {
            if (res?.data[0]?.status === "Эрдэнэтэд ирсэн") {
              if (list?.length > 0) {
                const find_item = _.find(
                  list,
                  (item) => item.track === res?.data[0]?.track
                );
                if (find_item) {
                  var check = _.find(list, {
                    track: res?.data[0]?.track,
                    select: true,
                  });
                  if (!check) {
                    const updatedList = _.map(list, (item) => {
                      if (item.track === find_item.track) {
                        return { ...item, select: true };
                      }
                      return item;
                    });
                    setList(updatedList);
                  } else {
                    toast.warn(`${code?.trim()} - Сонгогдсон байна.`);
                  }

                  inputRef1.current.focus();
                } else {
                  confirmDialog({
                    headerClassName: "p-2 text-center text-red-500",
                    contentClassName: "p-0",
                    footerClassName: "p-2",
                    header: `Өөр дугаарын ачаа бүртгэх гэж байна. Бүртгэх үү?`,
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Тийм",
                    rejectLabel: "Үгүй",
                    accept: () => {
                      setList((old) => {
                        return [...old, { ...res?.data[0], select: true }];
                      });
                      inputRef1.current.focus();
                    },
                    reject: () => {},
                  });
                }
              } else {
                setList((old) => {
                  return [...old, { ...res?.data[0], select: true }];
                });
              }
            } else {
              toast.warn(`${code?.trim()} - дугаартай ачаа бүртгэлгүй байна.`);
            }
          } else {
            toast.warn(`${code?.trim()} - дугаартай ачаа бүртгэлгүй байна.`);
          }
          setTrack("");
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePaidAndReceived = useCallback((list, type) => {
    if (_.filter(list, { select: true })?.length > 0) {
      confirmDialog({
        headerClassName: "p-2 text-center",
        contentClassName: "p-0",
        footerClassName: "p-2",
        header:
          "Ачааг хүлээж авсан төлөвт орох гэж байна. Та итгэлтэй байна уу?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Тийм",
        rejectLabel: "Үгүй",
        accept: () => {
          setLoading(true);
          post("/cargo/PaidAndReceived", {
            data: _.filter(list, { select: true }),
            type,
          })
            .then((res) => {
              if (res.success) {
                toast.success(res.data);
                setList([]);
              } else {
                toast.warn(res.error);
              }
            })
            .catch((err) => {
              toast.warn(err.message);
            })
            .finally(() => {
              setLoading(false);
            });
        },
        reject: () => {},
      });
    } else {
      toast.warn("Хаах ачаа байхгүй байна.");
    }
  }, []);

  const handleDelete = (track) => {
    setList((oldList) => oldList.filter((item) => item.track !== track));
  };

  const fetch_phone = async (phone) => {
    if (phone) {
      const isPhone = /^\d{8}$/.test(phone);
      if (!isPhone) {
        toast.warn("Утасны дугаар 8 оронтой байх ёстой.");
        return false;
      }
      try {
        setLoading(true);
        const res = await get(`/cargo/activelist/?active=1&phone=${phone}`);
        if (res?.success) {
          var list = [];
          _.map(res?.data, (item) => {
            list.push({ ...item, select: false });
          });
          setList(list);
          setPhone("");
          inputRef1.current.focus();
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      const updatedList = _.map(list, (item) => {
        return { ...item, select: selectAll };
      });
      setList(updatedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  useEffect(() => {
    get("/cargo/ubcheck").then((res) => {
      if (res?.success) {
        if (res?.data?.length > 0) {
          var pp = [];
          _.map(res?.data, (el) => {
            if (el?.phone) {
              pp.push(el?.phone);
            }
          });
          setPhones(pp);
        }
      }
    });
  }, []);

  useEffect(() => {
    var pps = phones;
    if (phone) {
      pps = _.filter(pps, (item) => {
        return item?.endsWith(phone);
      });
      setPhone_items(pps);
    }

    if (phone.length > 2) {
      setPshow(true);
    }
    if (phone.length === 8) {
      setPshow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div>
      {loading && <Loader />}
      <AdminHeader />
      <ConfirmDialog />
      <div className="flex flex-col gap-2 mt-2 px-2 container mx-auto">
        <div className="relative w-full">
          <div className="flex items-center justify-center mb-2">
            <div className="relative w-full">
              <input
                ref={inputRef0}
                type="text"
                className="bg-white border border-white text-gray-900 rounded-l block p-1.5 w-full outline-none"
                value={phone}
                placeholder="Утасны дугаар"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                autoFocus={true}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    if (phone_items.length === 1) {
                      await fetch_phone(phone_items[0]);
                    }
                    setPshow(false);
                  }
                }}
              />
              {phone ? (
                <div
                  className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
                  onClick={() => {
                    setPhone("");
                    inputRef0.current.focus();
                  }}
                >
                  <ClearIcon />
                </div>
              ) : (
                <div
                  className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white`}
                  onClick={() => {
                    navigator.clipboard
                      .readText()
                      .then(async (text) => {
                        await fetch_phone(text?.trim());
                      })
                      .catch(() => {
                        toast.info("Хуулсан текст байхгүй байна.");
                      });
                  }}
                >
                  <PasteIcon />
                </div>
              )}
              <div
                className={`${
                  pshow ? "pshow" : "hidden"
                } absolute top-10 left-2 z-10 bg-white shadow`}
              >
                {phone_items?.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="p-2 border-b cursor-pointer"
                      onClick={() => {
                        setPhone(el);
                        setPshow(false);
                        inputRef1.current.focus();
                      }}
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="relative w-full">
            <input
              ref={inputRef1}
              type="text"
              className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none mb-2"
              value={track}
              placeholder="Тээврийн код"
              onChange={(e) => {
                setTrack(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  check_track(e.target.value);
                }
              }}
            />
            {track ? (
              <div
                className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer`}
                onClick={() => {
                  setTrack("");
                  inputRef1.current.focus();
                }}
              >
                <ClearIcon />
              </div>
            ) : (
              <div
                className={`absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white`}
                onClick={() => {
                  navigator.clipboard
                    .readText()
                    .then((text) => {
                      setTrack(text?.trim());
                      check_track(text?.trim());
                    })
                    .catch(() => {
                      toast.info("Хуулсан текст байхгүй байна.");
                    });
                }}
              >
                <PasteIcon />
              </div>
            )}
          </div>

          <DataTable
            value={list}
            size="small"
            className="text-sm sm:text-base"
            emptyMessage="Хоосон байна."
            header={
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-center gap-2">
                  <div className="w-[35px]">
                    <Checkbox
                      onChange={(e) => {
                        setSelectAll(e.checked);
                      }}
                      checked={selectAll}
                      width={30}
                      height={30}
                      variant="filled"
                    />
                  </div>
                  <div>
                    {list?.length}/{_.filter(list, { select: true })?.length}
                  </div>{" "}
                  |
                  <div>
                    {_.sumBy(list, (o) => {
                      return parseInt(o.payment);
                    })}
                    /
                    {_.sumBy(_.filter(list, { select: true }), (o) => {
                      return parseInt(o.payment);
                    })}
                  </div>
                </div>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => {
                    if (list.length > 0) {
                      handlePaidAndReceived(list, "account");
                    } else {
                      toast.warn("Тараах жагсаалт хоосон байна.");
                    }
                  }}
                >
                  Өгсөн
                </button>
              </div>
            }
          >
            <Column
              field="select"
              header="#"
              className="w-[35px]"
              body={(data, row) => {
                return (
                  <Checkbox
                    onChange={(e) => {
                      const updatedList = _.map(list, (item, index) => {
                        if (index === row.rowIndex) {
                          return { ...item, select: e.checked };
                        }
                        return item;
                      });
                      setList(updatedList);
                    }}
                    checked={data?.select}
                    width={30}
                    height={30}
                    variant="filled"
                  />
                );
              }}
            />
            <Column
              field="id"
              header="№"
              body={(data, row) => {
                return row?.rowIndex + 1;
              }}
            />
            <Column field="track" header="Тээврийн код" />
            <Column field="phone" header="Утас" />
            <Column field="payment" header="Үнэ" />
            <Column
              field="paid"
              header="Төлбөр"
              body={(data) => {
                return data.paid ? (
                  <div className="px-2 py-1 bg-green-500 rounded text-white">
                    Төлөгдсөн
                  </div>
                ) : (
                  <div className="px-2 py-1 bg-red-500 rounded text-white">
                    Төлөөгүй
                  </div>
                );
              }}
            />
            <Column
              field="id"
              header="#"
              body={(data) => {
                return (
                  <FaRegTrashAlt
                    className="cursor-pointer text-2xl text-red-600"
                    onClick={() => handleDelete(data.track)}
                  />
                );
              }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Give);
