import React, { useContext } from "react";
import { RiMenu2Fill } from "react-icons/ri";
import { Context } from "../context";

const AdminHeader = () => {
  const { dispatch } = useContext(Context);
  return (
    <div className="w-full h-10 bg-white flex items-center justify-between relative">
      <RiMenu2Fill
        className="w-10 h-6 cursor-pointer"
        onClick={() => {
          dispatch({
            type: "CHANGE",
            data: { sidebar: true },
          });
        }}
      />
      <div></div>
    </div>
  );
};

export default React.memo(AdminHeader);
