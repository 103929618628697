import React, { useState } from "react";
import { toast } from "react-toastify";
import { getparams, post } from "../axios";
import moment from "moment";
import { ClearIcon } from "./Icons";
import Loader from "./loader";

const MissModal = ({ modal, setModal }) => {
  const phone = localStorage.getItem("phone");
  const [data, setData] = useState({
    track: "",
    phone: phone ? phone : "",
    name: "",
    registered: "",
  });
  const [message, setMessage] = useState({
    text: "",
    show: false,
  });
  const [loading, setLoading] = useState(false);

  const clear = () => {
    setData((old) => ({
      ...old,
      track: "",
      name: "",
      registered: "",
    }));
    setMessage({
      text: "",
      show: false,
    });
  };

  const MissCreate = async () => {
    if (data.track && data.phone) {
      setLoading(true);
      const res = await getparams("/cargo/check/" + data?.track);
      var ckeck = false;
      if (res?.data?.length > 0) {
        if (res?.data[0]?.status === "Эрдэнэтэд ирсэн") {
          setMessage({
            text: `Таны ачаа ${moment(res.data[0].registered).format(
              "YYYY-MM-DD"
            )} өдөр ЭРДЭНЭТЭД ирсэн байна. `,
            show: true,
          });
          toast.info("Таны ачаа ЭРДЭНЭТЭД ирсэн байна.");
          setLoading(false);
        } else if (res?.data[0]?.status === "Өгсөн") {
          setMessage({
            text: `Таны энэ ачааг ${moment(res.data[0].delivered).format(
              "YYYY-MM-DD"
            )} өдөр хүлээж авсан байна. `,
            show: true,
          });
          toast.info("Та энэ ачаа хүлээж авсан байна.");
          setLoading(false);
        } else {
          ckeck = true;
        }
      } else {
        toast.info(
          `${data?.track} - тээврийн кодтой ачаа эрээний хаягт хүргэгдээгүй байна.`
        );
        setMessage({
          text: `${data?.track} - тээврийн кодтой бараа эрээний хаягт хүргэгдээгүй байна. Та худалдан авалт хийсэн дэлгүүртэйгээ холбогдож бараагаа шалгуулна уу.`,
          show: true,
        });
        setLoading(false);
      }
      if (ckeck) {
        post("/cargo/miss", data)
          .then((res) => {
            if (res.success) {
              toast.success(
                "Таны ачааг амжилттай бүртгэлээ. Бид удахгүй таньтай холбогдох болно."
              );
              setModal((prev) => ({ ...prev, missModal: false }));
              clear();
            } else {
              toast.warn(res?.data);
            }
          })
          .catch((err) => {
            toast.warn(err?.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      toast.warn("Тээврийн код болон Утасны дугаар оруулна уу.");
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal((prev) => ({ ...prev, missModal: false }));
          clear();
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b">
          <div className="font-semibold tracking-wide uppercase md:text-xl">
            Ирэхгүй байгаа ачааны мэдээлэл бүртгэх
          </div>
          <div
            onClick={() => {
              setModal((prev) => ({ ...prev, missModal: false }));
              clear();
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="flex items-center justify-center flex-col">
          <div
            className={`${
              message?.show ? "block" : "hidden"
            } p-2 font-semibold text-gray-900`}
          >
            {message.text}
          </div>
          <input
            type="text"
            className="bg-white border text-sm text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Трак код буюу баркод"
            value={data?.track}
            onChange={(e) => {
              setData((old) => ({ ...old, track: e.target.value }));
            }}
          />
          <input
            type="number"
            className="bg-white border text-sm text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Утасны дугаар"
            value={data?.phone}
            onChange={(e) => {
              setData((old) => ({ ...old, phone: e.target.value }));
              localStorage.setItem("phone", e.target.value);
            }}
          />
          <textarea
            rows={3}
            className="bg-white border text-sm text-gray-900 rounded-l block p-2.5 w-full outline-none"
            placeholder="Ямар ачаа вэ? тайлбар оруулна уу! ор, хувцас, хайч, майхан гэх мэт."
            value={data?.name}
            onChange={(e) => {
              setData((old) => ({ ...old, name: e.target.value }));
            }}
          />
          <div
            className="px-4 py-1 mt-4 bg-green-600 text-white rounded mb-4 cursor-pointer"
            onClick={() => MissCreate()}
          >
            Илгээх
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(MissModal);
