import React, { useContext } from "react";
import { Context } from "../context";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const { state, dispatch } = useContext(Context);
  const location = useLocation();
  const menu = [
    { name: "Нүүр хуудас", path: "/" },
    { name: "Байгаа бараа", path: "/arrived" },
    { name: "Тараалт", path: "/give" },
    { name: "Өгсөн бараа", path: "/delivered" },
    { name: "Камера бүртгэл", path: "/camera" },
    { name: "Сканер бүртгэл", path: "/scanner" },
    { name: "Дансны хуулга", path: "/file" },
    { name: "Зардал / Ашиг", path: "/cost" },
    { name: "Ирэхгүй байгаа ачаа бүртгэл", path: "/ub" },
    { name: "Уран сайт", path: "/uran" },
    { name: "Ачаа тулгах", path: "/check" },
    { name: "Хэрэглэгчийн цол", path: "/rank" },
    { name: "Захиалга", path: "/orders" },
  ];

  return (
    <div>
      <div
        className={`h-screen w-screen bg-black/50 overflow-hidden transition-opacity duration-300 ${
          state?.sidebar ? "fixed opacity-100" : "hidden opacity-0"
        } top-0 left-0 z-40`}
        onClick={() => {
          dispatch({
            type: "CHANGE",
            data: { sidebar: false },
          });
        }}
      />
      <div
        className={`transform top-0 left-0 w-2/3 sm:w-[300px] h-screen bg-white fixed transition-transform duration-300 z-[99] ${
          state?.sidebar ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4">
          {menu.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`block p-2 text-black hover:bg-gray-200 border-b ${
                location?.pathname === item?.path ? "bg-[#F08800]" : ""
              }`}
              onClick={() => {
                dispatch({
                  type: "CHANGE",
                  data: { sidebar: false },
                });
              }}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
