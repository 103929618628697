import React, { useState, useCallback, useContext } from "react";
import { getparams, post, trash } from "./axios";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import copy from "copy-to-clipboard";
import { Context } from "./context";
import AdminHeader from "./components/adminHeader";

import { Calendar } from "primereact/calendar";
import Loader from "./components/loader";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Link } from "react-router-dom";

const DeliveredList = () => {
  const { state } = useContext(Context);
  const [data, setData] = useState({
    startdate: moment()._d,
    enddate: moment()._d,
    track: "",
    phone: "",
    payment: "",
    active: "delivered",
    loading: false,
    groupList: [],
    cash: 0,
    account: 0,
  });

  const handleTrash = (id) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header: "Ачааны мэдээлэл устах гэж байна. Устгахдаа итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: () => {
        if (id) {
          setData((prev) => ({
            ...prev,
            loading: true,
          }));
          trash(`/cargo/delete/${id}`)
            .then((res) => {
              if (res.success) {
                toast.success(res.data);
              } else {
                toast.warn(res.error);
              }
            })
            .catch((err) => {
              toast.warn(err.message);
            })
            .finally(() => {
              setData((prev) => ({
                ...prev,
                loading: false,
              }));
            });
        } else {
          toast.warn("ID байхгүй байна.");
        }
      },
      reject: () => {},
    });
  };

  const UnPaidAndUnReceived = (list) => {
    confirmDialog({
      headerClassName: "p-2 text-center",
      contentClassName: "p-0",
      footerClassName: "p-2",
      header:
        "Ачааны мэдээлэл буцаж Эрдэнэтэд ирсэн төлөвт орох гэж байна. Та итгэлтэй байна уу?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Тийм",
      rejectLabel: "Үгүй",
      accept: () => {
        if (list?.length > 0) {
          setData((prev) => ({
            ...prev,
            loading: true,
          }));
          post("/cargo/UnPaidAndUnReceived", { data: list })
            .then((res) => {
              if (res.success) {
                toast.success(res.data);
              } else {
                toast.warn(res?.error);
              }
            })
            .catch((err) => {
              toast.warn(err?.message);
            })
            .finally(() => {
              setData((prev) => ({
                ...prev,
                loading: false,
              }));
            });
        } else {
          toast.warn("Жагсаалт хоосон байна.");
        }
      },
      reject: () => {},
    });
  };

  const handleSearch = useCallback(() => {
    setData((prev) => ({
      ...prev,
      loading: true,
    }));
    var params = {};
    if (data.active === "arrived") {
      params.active = 1;
    } else if (data.active === "delivered") {
      params.active = 0;
    }

    if (data.startdate)
      params.startdate = moment(data.startdate).format("YYYY-MM-DD");
    if (data.enddate)
      params.enddate = moment(data.enddate).format("YYYY-MM-DD") + " 24:00:00";
    if (data.payment) params.payment = data.payment;
    if (data.track) params.track = data.track;
    if (data.phone) params.phone = data.phone;

    getparams("/cargo/activelist", params)
      .then((res) => {
        if (res.success) {
          const groupedData = _.sortBy(
            Object.entries(_.groupBy(res.data, "phone")),
            [
              (o) => {
                if (o?.length > 0) {
                  if (o[1]?.length > 0) {
                    return o[1][0].delivered;
                  }
                }
              },
            ]
          );

          setData((prev) => ({
            ...prev,
            groupList: groupedData,
            cash: _.sumBy(_.filter(res.data, { paid_type: "cash" }), (el) => {
              return parseInt(el?.payment);
            }),
            account: _.sumBy(
              _.filter(res.data, { paid_type: "account" }),
              (el) => {
                return parseInt(el?.payment);
              }
            ),
          }));
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      })
      .finally(() => {
        setData((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  }, [
    data.active,
    data.enddate,
    data.payment,
    data.phone,
    data.startdate,
    data.track,
  ]);

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div className="container mx-auto">
      <ConfirmDialog />
      {data?.loading && <Loader />}
      {/* header section */}
      <div className="sticky top-0 bg-white z-10">
        <AdminHeader />
        <div className="flex items-center justify-center">
          <Calendar
            className="w-full border-none px-2 text-sm"
            placeholder="Эхлэх огноо"
            showButtonBar
            value={data?.startdate}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                startdate: e.value,
              }))
            }
          />
          <Calendar
            className="w-full border-none py-2 px-2 text-sm"
            placeholder="Дуусах огноо"
            showButtonBar
            value={data?.enddate}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                enddate: e.value,
              }))
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="number"
            placeholder="Мөнгөн дүн"
            value={data?.payment}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                payment: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <select
            className="bg-white text-gray-900 block p-2 w-full outline-none"
            value={data?.active}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                active: e.target.value,
              }))
            }
          >
            <option value="all">Бүгд</option>
            <option value="arrived">Ирсэн</option>
            <option value="delivered">Өгсөн</option>
          </select>
        </div>
        <div className="flex items-center justify-center flex-col">
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="text"
            placeholder="Кодоор хайх"
            value={data?.track}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                track: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <input
            className="bg-white border border-gray-100 text-gray-900 block p-2 w-full outline-none"
            type="number"
            placeholder="Утсаар хайх"
            value={data?.phone}
            onChange={(e) =>
              setData((prev) => ({
                ...prev,
                phone: e.target.value,
              }))
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
        </div>
        <div
          className="px-4 py-1 bg-green-500 text-center text-white font-medium cursor-pointer"
          onClick={() => {
            handleSearch();
          }}
        >
          Хайх
        </div>
      </div>

      {/* header section */}
      <div className="w-full overflow-auto text-xs md:text-base">
        {state?.userId === 1 && (
          <div>
            <div>Бэлэн: {data?.cash}₮</div>
            <div>Данс: {data?.account}₮</div>
          </div>
        )}

        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Утас</th>
              <th>Код</th>
              <th>Нэгж үнэ</th>
              <th>Бүртгэсэн</th>
              <th>Ирсэн</th>
              <th>Өгсөн</th>
              <th>Хоног</th>
              {state?.userId === 1 && <th>#</th>}
            </tr>
          </thead>
          <tbody>
            {_.map(data.groupList, ([phone, items], index) => {
              const totalPayment = _.sumBy(
                items,
                (item) => parseInt(item.payment) || 0
              );
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="text-left" rowSpan={items.length + 1}>
                      <div className="flex flex-col items-center gap-1">
                        <div className="font-medium">
                          <div onClick={() => copy(phone.trim())}>{phone}</div>
                          <div>{items.length}ш</div>
                          <div>{totalPayment.toLocaleString()}</div>
                        </div>
                        <div className="flex items-center justify-center gap-1">
                          <div
                            className="p-1 rounded bg-red-600 text-white cursor-pointer text-center font-thin"
                            onClick={() => {
                              UnPaidAndUnReceived(items);
                            }}
                          >
                            Буцаах
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {_.map(items, (item, index2) => (
                    <tr
                      key={`${index}-${index2}`}
                      className={item.paid ? "bg-green-600" : ""}
                    >
                      <td
                        className={`text-left ${
                          item.now === "1" ? "bg-green-600" : ""
                        }`}
                      >
                        {item.track}
                      </td>
                      <td className="text-left">
                        {parseInt(item.payment).toLocaleString()}
                      </td>
                      <td className="text-left whitespace-nowrap">
                        {moment(item.registered).format("YYYY-MM-DD")}
                      </td>
                      <td className="text-left whitespace-nowrap">
                        {moment(item.arrived).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td className="text-left whitespace-nowrap">
                        {moment(item.delivered).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        {item.arrived &&
                          item.registered &&
                          moment(item.arrived).diff(
                            moment(item.registered),
                            "days"
                          )}
                      </td>
                      {state?.userId === 1 && (
                        <td>
                          <div className="flex items-center justify-center gap-2">
                            <span
                              className="p-1 bg-red-600 text-white rounded cursor-pointer"
                              onClick={() => handleTrash(item.id)}
                            >
                              Устгах
                            </span>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(DeliveredList);
