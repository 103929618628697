import React from "react";
import { ClearIcon } from "./Icons";

const Link = ({ modal, setModal }) => {
  return (
    <React.Fragment>
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal((prev) => ({ ...prev, linkModal: false }));
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b">
          <div className="font-semibold tracking-wide uppercase md:text-xl">
            Линк захиалга
          </div>
          <div
            onClick={() => {
              setModal((prev) => ({ ...prev, linkModal: false }));
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="w-full overflow-auto text-xs md:text-base flex items-center justify-center flex-col">
          <div className="flex gap-2 px-2">
            <a
              href="https://www.facebook.com/uuruuzahialay"
              className="py-2 font-semibold uppercase text-center bg-[#F55B00] text-white rounded-md my-4 p-2"
              target="_blank"
              rel="noreferrer"
            >
              Сургалтын файж хуудасруу очих
            </a>
            <a
              href="https://www.facebook.com/erdenetcargo.mn"
              className="py-2 font-semibold uppercase text-center bg-[#F55B00] text-white rounded-md my-4 p-2"
              target="_blank"
              rel="noreferrer"
            >
              Каргоны файж хуудасруу очих
            </a>
          </div>
          <div className="text-base text-justify px-4">
            <div className="uppercase font-medium">
              Линкээр захиалга хийлгэх гэрээ:
            </div>
            ● Та өөрийн захиалах бараагаа, дэлгүүрээ судалж линкээ тодорхой
            явуулаарай.
            <br />
            ● Учир нь бид таны сонголтын дагуу захиалга хийх учир дараа учрах
            эрсдэл:
            <br />
            ● Бараа дутуу ирэх, Өнгө размер зөрж ирэх, Дэлгүүрээс олон салгаж
            баглах, Дэлгүүр удааж хүргэлтэнд гаргах, Дэлгүүр талаас буруу хаяг
            дээр хүргэх зэрэг асуудлуудад бид хариуцлага хүлээхгүй болохыг
            анхаарна уу‼
            <br />
            ● Таны ачаа Хятад дотроо өөр өөр хотоос Эрээнлүү хүргэгддэг бөгөөд
            тухайн дэлгүүр нь хэд хоногийн дотор таны барааг гаргах нь харилцан
            адилгүй байдаг. Иймд 1 өдөр захиалсан бараанууд бүгд хамт нэг өдөр
            хүргэгдэнэ гэсэн үг биш юм.
            <br />
            ● Эрдэнэт каргоны зүгээс зөвхөн таны илгээсэн линк буюу таны
            сонгосон сонголтын дагуу захиалгыг хийж гүйцэтгэнэ.
            <br />
            💸 Бараагаа ирхээр нь каргоны мөнгөө төлөөд авна.
            <br />
            ❌ Линкээр захиалга хийгдсэн барааг хаана яваа талаар шалгаж өгөх
            Боломжгүй.
            <br />❌ Доорх бичигдсэн хугацаанд таны бараа ирээгүй тохиолдолд
            Шалгаж өгөх боломжтой. <br />
            🚛 Дэлгүүрээс - Эрээн дэх хаяг дээр очих хугацаа 3-7 хоног байдаг.
            <br />
            🚛 Эрээнээс - Эрдэнэт ирэх хугацаа 7-14 хоног байдаг.
            <br />
            Та эдгээр заалтуудыг зөвшөөрч байвал захиалгаа хийлгэж болно.
            Баярлалаа😊
          </div>
          <iframe
            title="Facebook Video"
            src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Ferdenetcargo.mn%2Fvideos%2F1015494049420136%2F&show_text=false&width=220&t=0"
            width={220}
            height={476}
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameBorder={0}
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Link;
