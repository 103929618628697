import React, { useContext, useRef, useState } from "react";
import { Context } from "./context";
import { toast } from "react-toastify";
import { get, put } from "./axios";
import Loader from "./components/loader";
import AdminHeader from "./components/adminHeader";
import { Link } from "react-router-dom";

const Check = () => {
  const inputRef1 = useRef();
  const [track, setTrack] = useState("");
  const { state } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const check_track = async (code) => {
    if (code) {
      try {
        setLoading(true);
        const res = await get(`/cargo/check/${code?.trim()}`);
        if (res?.success) {
          if (res?.data?.length > 0) {
            await put("/cargo/write", { ...res?.data[0], now: 1 });
            setData(res?.data[0]);
            setTrack("");
          }
        } else {
          toast.warn(res.error);
        }
      } catch (error) {
        toast.warn(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div>
      {loading && <Loader />}
      <AdminHeader />
      <div className="flex flex-col gap-2 mt-2 px-2 container mx-auto">
        <div className="relative w-full">
          <input
            ref={inputRef1}
            type="text"
            className="bg-white border border-white text-gray-900 rounded block p-1.5 w-full outline-none"
            value={track}
            placeholder="Тээврийн код"
            onChange={(e) => {
              setTrack(e.target.value);
            }}
            autoFocus={true}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                check_track(e.target.value);
                inputRef1.current.focus();
              }
            }}
          />
          <div className="text-white text-xl">
            <div>{data?.track}</div>
            <div>{data?.status}</div>
            <div>{data?.phone}</div>
            <div>{data?.payment}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Check);
