import React from "react";
import AppContext from "./context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import Home from "./Home";
import ArrivedList from "./ArrivedList";
import Give from "./give";
import Layout from "./layout";
import Login from "./login";
import Camera from "./camera";
import Scanner from "./Scanner";
import Check from "./check";
import File from "./file";
import Cost from "./Cost";
import UbList from "./UbList";
import DeliveredList from "./DeliveredList";
import HomeFooter from "./components/HomeFooter";
import Uran from "./uran";
//SHOPING
import Orders from "./orders";
import Rank from "./rank";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Home />
          <HomeFooter />
        </Layout>
      ),
    },
    {
      path: "/login",
      element: (
        <Layout>
          <Login />
        </Layout>
      ),
    },
    {
      path: "/give",
      element: (
        <Layout>
          <Give />
        </Layout>
      ),
    },
    {
      path: "/arrived",
      element: (
        <Layout>
          <ArrivedList />
        </Layout>
      ),
    },
    {
      path: "/delivered",
      element: (
        <Layout>
          <DeliveredList />
        </Layout>
      ),
    },
    {
      path: "/camera",
      element: (
        <Layout>
          <Camera />
        </Layout>
      ),
    },
    {
      path: "/scanner",
      element: (
        <Layout>
          <Scanner />
        </Layout>
      ),
    },
    {
      path: "/check",
      element: (
        <Layout>
          <Check />
        </Layout>
      ),
    },
    {
      path: "/file",
      element: (
        <Layout>
          <File />
        </Layout>
      ),
    },
    {
      path: "/cost",
      element: (
        <Layout>
          <Cost />
        </Layout>
      ),
    },
    {
      path: "/ub",
      element: (
        <Layout>
          <UbList />
        </Layout>
      ),
    },
    {
      path: "/uran",
      element: (
        <Layout>
          <Uran />
        </Layout>
      ),
    },
    {
      path: "/rank",
      element: (
        <Layout>
          <Rank />
        </Layout>
      ),
    },
    {
      path: "/orders",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
  ]);

  return (
    <PrimeReactProvider>
      <AppContext>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
        <RouterProvider router={router} />
      </AppContext>
    </PrimeReactProvider>
  );
};

export default React.memo(App);
