import React from "react";
import { ClearIcon } from "./Icons";

const Forbidden = ({ modal, setModal }) => {
  return (
    <React.Fragment>
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal((prev) => ({ ...prev, for_modal: false }));
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b">
          <div className="font-semibold tracking-wide uppercase md:text-xl">
            Хориотой бараа
          </div>
          <div
            onClick={() => {
              setModal((prev) => ({ ...prev, for_modal: false }));
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="w-full overflow-auto text-xs md:text-base flex items-center justify-center flex-col">
          <div className="flex gap-2 px-2">
            <a
              href="https://www.facebook.com/uuruuzahialay"
              className="py-2 font-semibold uppercase text-center bg-[#F55B00] text-white rounded-md my-4 p-2"
              target="_blank"
              rel="noreferrer"
            >
              Сургалтын файж хуудасруу очих
            </a>
            <a
              href="https://www.facebook.com/erdenetcargo.mn"
              className="py-2 font-semibold uppercase text-center bg-[#F55B00] text-white rounded-md my-4 p-2"
              target="_blank"
              rel="noreferrer"
            >
              Каргоны файж хуудасруу очих
            </a>
          </div>
          <div className="text-base px-4">
            <div className="font-semibold text-gray-800 py-2 uppercase">
              ОГТ ЗАХИАЛЖ БОЛОХГҮЙ
              <span className="text-red-600"> ХОРИОТОЙ </span> БАРАА
            </div>
            <div className="uppercase mb-4 text-justify">
              <a
                className="text-blue-600"
                href="https://post.customs.gov.mn/wp-content/uploads/2019/09/%D0%A5%D0%BE%D1%80%D0%B8%D0%B3%D0%BB%D0%BE%D1%81%D0%BE%D0%BD-%D1%85%D1%8F%D0%B7%D0%B3%D0%B0%D0%B0%D1%80%D0%BB%D0%B0%D1%81%D0%B0%D0%BD-%D0%B1%D0%B0%D1%80%D0%B0%D0%B0%D0%BD%D1%8B-%D0%B6%D0%B0%D0%B3%D1%81%D0%B0%D0%B0%D0%BB%D1%82.pdf"
                target="_blank"
                rel="noreferrer"
              >
                МОНГОЛ УЛСЫН ХИЛЭЭР НЭВТРҮҮЛЭХИЙГ ХОРИГЛОХ БАРААНЫ ЖАГСААЛТ
              </a>
              <div>❌ Бүх төрлийн шингэн зүйл</div>
              <div>❌ Бүх төрлийн хүнс</div>
              <div>
                ❌ Галт зэвсэг, тэсэрч дэлбэрэх болон цацраг идэвхт бодис
              </div>
              <div>
                ❌ Монгол улсын гаалийн тухай хуульд тусгасан хориотой бүх
                төрлийн бараа
              </div>
              <div>
                ❌ Хориотой бараа захиалснаас өөрт болон бусад захиалагч,
                каргонд Үүссэн аливаа хохирлыг захиалагч бүрэн барагдуулна.
              </div>
            </div>
            <div className="font-semibold text-gray-800 py-2 uppercase">
              ЗАХИАЛАГЧ
              <span className="text-red-600"> ӨӨРӨӨ ХАРИУЦАХ </span> ТОХИОЛДОЛД
              ЗӨВШӨӨРӨГДӨХ БАРАА
            </div>
            <div className="uppercase mb-4 text-justify">
              <div>⚠️ Шил шаазан эдлэл, хуванцар</div>
              <div>
                ⚠️ зурагт, компьютер, нөүтбүүк, дэлгэц / олон тоогоор хориотой /
              </div>
              <div>⚠️ Гар утас, таблет / олон тоогоор хориотой /</div>
              <div>⚠️ Чулуун ширээ, сандал, тавилга</div>
              <div>
                ⚠️ Олон тооны ачих буулгах үйлдэл хийгддэг учир эвдрэх бараа
                захиалсан тохиолдолд дэлгүүрээсээ сав баглааг маш сайн хийхийг
                сануулах.
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Forbidden;
