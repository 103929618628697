import React, { useEffect, useState, useContext, useRef } from "react";
import { getparams } from "./axios";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { ClearIcon, PasteIcon } from "./components/Icons";
import copy from "copy-to-clipboard";
import { Context } from "./context";

import { ConfirmDialog } from "primereact/confirmdialog";
import AdminHeader from "./components/adminHeader";
import Loader from "./components/loader";
import { Link } from "react-router-dom";

const calculateTotalPayment = (list) => {
  return list.reduce((acc, curr) => acc + (parseInt(curr.payment) || 0), 0);
};

const filterListBySearchText = (list, searchText) => {
  if (!searchText) return list;
  return _.filter(list, (el) =>
    _.some(["phone", "track", "payment", "registered", "arrived"], (key) =>
      _.includes(_.toString(el[key]), searchText)
    )
  );
};

const groupListByPhone = (list) => {
  return Object.entries(_.groupBy(list, "phone"));
};

const ArrivedList = () => {
  const { state } = useContext(Context);
  const [data, setData] = useState({
    totalPayment: 0,
    list: [],
    groupList: [],
    filteredListLength: 0,
  });
  const [searchText, setSearchText] = useState("");
  const [refresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef();

  // const handlePaidAndReceived = useCallback((list, type) => {
  //   if (list.length > 0) {
  //     confirmDialog({
  //       headerClassName: "p-2 text-center",
  //       contentClassName: "p-0",
  //       footerClassName: "p-2",
  //       header:
  //         "Ачааг хүлээж авсан төлөвт орох гэж байна. Та итгэлтэй байна уу?",
  //       icon: "pi pi-exclamation-triangle",
  //       acceptLabel: "Тийм",
  //       rejectLabel: "Үгүй",
  //       accept: () => {
  //         setLoading(true);
  //         post("/cargo/PaidAndReceived", { data: list, type })
  //           .then((res) => {
  //             if (res.success) {
  //               toast.success(res.data);
  //               setRefresh((prev) => prev + 1);
  //               setSearchText("");
  //               searchRef.current.focus();
  //             } else {
  //               toast.warn(res.error);
  //             }
  //           })
  //           .catch((err) => {
  //             toast.warn(err.message);
  //           })
  //           .finally(() => {
  //             setLoading(false);
  //           });
  //       },
  //       reject: () => {},
  //     });
  //   } else {
  //     toast.warn("Жагсаалт хоосон байна.");
  //   }
  // }, []);

  useEffect(() => {
    const params = { payment: "not null", active: 1 };
    setLoading(true);
    getparams("/cargo/activelist", params)
      .then((res) => {
        if (res.success) {
          setData((prev) => ({
            ...prev,
            list: res.data,
          }));
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    const filteredList = filterListBySearchText(data.list, searchText);
    const groupedByPhone = groupListByPhone(filteredList);
    const totalPayment = calculateTotalPayment(filteredList);

    setData((prev) => ({
      ...prev,
      groupList: groupedByPhone,
      totalPayment: totalPayment,
      filteredListLength: filteredList.length,
    }));
  }, [data.list, searchText]);

  // const handleTrash = (id) => {
  //   confirmDialog({
  //     headerClassName: "p-2 text-center",
  //     contentClassName: "p-0",
  //     footerClassName: "p-2",
  //     header: "Ачааны мэдээлэл устгахдаа итгэлтэй байна уу?",
  //     icon: "pi pi-exclamation-triangle",
  //     acceptLabel: "Тийм",
  //     rejectLabel: "Үгүй",
  //     accept: () => {
  //       if (id) {
  //         setLoading(true);
  //         trash(`/cargo/delete/${id}`)
  //           .then((res) => {
  //             if (res.success) {
  //               toast.success(res.data);
  //               setRefresh((prev) => prev + 1);
  //             } else {
  //               toast.warn(res.error);
  //             }
  //           })
  //           .catch((err) => {
  //             toast.warn(err.message);
  //           })
  //           .finally(() => {
  //             setLoading(false);
  //           });
  //       } else {
  //         toast.warn("ID байхгүй байна.");
  //       }
  //     },
  //     reject: () => {},
  //   });
  // };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );
  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div className="container mx-auto">
      {loading && <Loader />}
      <ConfirmDialog />
      {/* header section */}
      <div className="sticky top-0 bg-white z-10 flex items-center justify-center flex-col">
        <AdminHeader />
        <div className="relative w-full">
          <input
            ref={searchRef}
            className="bg-white border border-gray-100 text-gray-900 block p-1.5 w-full outline-none h-[35px]"
            type="search"
            placeholder="Хайх..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText ? (
            <div
              className="absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer"
              onClick={() => setSearchText("")}
            >
              <ClearIcon />
            </div>
          ) : (
            <div
              className="absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer bg-white"
              onClick={() => {
                navigator.clipboard
                  .readText()
                  .then((text) => {
                    let rm_space_text = text.replace(/\s+/g, "");
                    setSearchText(rm_space_text?.trim());
                  })
                  .catch(() => {
                    toast.info("Хуулсан текст байхгүй байна.");
                  });
              }}
            >
              <PasteIcon />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center text-xl font-bold text-white my-2">
        <span>НИЙТ: {data.filteredListLength}ш </span>
        {state?.userId === 1 && (
          <span> | {data.totalPayment.toLocaleString()}₮</span>
        )}
      </div>
      {/* header section */}
      <div className="w-full overflow-auto text-xs md:text-base">
        <a
          href={`sms://open?addresses=${data.list
            .map((el) => el.phone)
            .toString()}?&body=Sain baina uu, tanid achaa irsen bn. Achaa awah hayg: Uurhaichin-1 hothon 4-r bair Utas:96060645, website: https://erdenetcargo.mn`}
          className="py-1 px-2 bg-green-600 rounded"
        >
          Send SMS
        </a>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th>Утас</th>
              <th>Код</th>
              <th>Нэгж үнэ</th>
              <th>Бүртгэсэн</th>
              <th>Ирсэн</th>
              <th>Хоног</th>
              {/* {state?.userId === 1 && <th>#</th>} */}
            </tr>
          </thead>
          <tbody>
            {_.map(data.groupList, ([phone, items], index) => {
              const totalPayment = _.sumBy(
                items,
                (item) => parseInt(item.payment) || 0
              );
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="text-left" rowSpan={items.length + 1}>
                      <div className="flex flex-col items-center gap-1">
                        <div className="font-medium">
                          <div onClick={() => copy(phone.trim())}>{phone}</div>
                          <div>{items.length}ш</div>
                          <div>{totalPayment.toLocaleString()}</div>
                        </div>
                        {/* <div className="flex items-center justify-center gap-1">
                          <div
                            className="p-1 rounded bg-green-600 text-white cursor-pointer text-center font-thin"
                            onClick={() => handlePaidAndReceived(items, "cash")}
                          >
                            Бэлэн
                          </div>
                          <div
                            className="p-1 rounded bg-green-600 text-white cursor-pointer text-center font-thin"
                            onClick={() =>
                              handlePaidAndReceived(items, "account")
                            }
                          >
                            Данс
                          </div>
                        </div> */}
                      </div>
                    </td>
                  </tr>
                  {_.map(items, (item, index2) => (
                    <tr
                      key={`${index}-${index2}`}
                      className={item.paid ? "bg-green-600" : ""}
                    >
                      <td
                        className={`text-left ${
                          item.now === "1" ? "bg-green-600" : ""
                        }`}
                      >
                        {item.track}
                      </td>
                      <td className="text-left">
                        {parseInt(item.payment).toLocaleString()}
                      </td>
                      <td className="text-left whitespace-nowrap">
                        {moment(item.registered).format("YYYY-MM-DD")}
                      </td>
                      <td className="text-left whitespace-nowrap">
                        {moment(item.arrived).format("YYYY-MM-DD HH:mm")}
                      </td>
                      <td>
                        {item.arrived &&
                          item.registered &&
                          moment(item.arrived).diff(
                            moment(item.registered),
                            "days"
                          )}
                      </td>
                      {/* {state?.userId === 1 && (
                        <td>
                          <div className="flex items-center justify-center gap-2">
                            <span
                              className="p-1 bg-red-600 text-white rounded cursor-pointer"
                              onClick={() => handleTrash(item.id)}
                            >
                              Устгах
                            </span>
                          </div>
                        </td>
                      )} */}
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(ArrivedList);
