import React, { useContext, useState } from "react";
import AdminHeader from "./components/adminHeader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import Loader from "./components/loader";
import { toast } from "react-toastify";
import { post } from "./axios";
import { Context } from "./context";
import { Link } from "react-router-dom";

const config = {
  apiKey: "AIzaSyAF0vldKiqcTqKQFiiBqDdGZIn4PdCbeiw",
  authDomain: "kargo-a96b0.firebaseapp.com",
  projectId: "kargo-a96b0",
  storageBucket: "kargo-a96b0.appspot.com",
  messagingSenderId: "1050530103809",
  appId: "1:1050530103809:web:82229e986377dc51d7f600",
};

const app = initializeApp(config);
const defaultFirestore = getFirestore(app);
const fRequestCollection = collection(defaultFirestore, "fRequest");

const Uran = () => {
  const { state } = useContext(Context);
  const [list, setList] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const Ub = async (list) => {
    await post("/cargo/ub", { data: list });
  };

  const search = async () => {
    if (text) {
      setLoading(true);
      const isPhone = /^\d{8}$/.test(text);
      let db = query(fRequestCollection, where("status", "==", "1"));

      if (isPhone) {
        db = query(db, where("phone", "==", text));
      } else {
        db = query(db, where("code", "==", text));
      }
      try {
        const querySnapshot = await getDocs(db);
        const list = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });

        if (list?.length > 0) {
          setList(list);
          Ub(list);
        } else {
          toast.warn("Бараа олдсонгүй.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.warn("Хайх утга оруулна уу.");
    }
  };

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  if (state?.userId !== 1)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Хандах эрхгүй.</div>
        <Link
          to={"/"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нүүр хуудасруу буцах
        </Link>
      </div>
    );

  return (
    <div>
      <AdminHeader />
      {loading && <Loader />}
      <DataTable
        value={list}
        size="small"
        className="text-sm sm:text-base"
        emptyMessage="Хоосон байна."
        header={
          <input
            className="bg-white border text-xs text-gray-900 block p-2.5 w-full outline-none h-[38px]"
            placeholder="Утас эсвэл тээврийн код"
            type="text"
            value={text || ""}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value) {
                search();
              }
            }}
          />
        }
      >
        <Column field="phone" header="Утас" />
        <Column field="code" header="Тээврийн код" />
        <Column field="price" header="Үнэ" />
      </DataTable>
    </div>
  );
};

export default React.memo(Uran);
