import React from "react";
import { ClearIcon } from "./Icons";

const PriceModal = ({ modal, setModal }) => {
  return (
    <React.Fragment>
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setModal((prev) => ({ ...prev, modal_price: false }));
        }}
      />
      <div
        className={`${
          modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b">
          <div className="font-semibold tracking-wide uppercase md:text-xl">
            Үнийн мэдээлэл
          </div>
          <div
            onClick={() => {
              setModal((prev) => ({ ...prev, modal_price: false }));
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>
        <div className="w-full overflow-auto text-base text-gray-600 flex-col p-2">
          <div>1кг: 3000₮</div>
          <div>1м.куб: ачаа: 400,000₮</div>
          <div>1тн ачаа: 1,800,000₮</div>
          <div className="font-semibold text-gray-800 py-2 uppercase">
            ТЭЭВРИЙН ЗАРДАЛ БОДОХ АРГАЧЛАЛ
          </div>
          <div>
            ● Тусдаа ирсэн болон өөр дэлгүүрийн барааг тус тусад нь бодно.
          </div>
          <div>● Кейс, чихэвч гм бараа 2000₮</div>
          <div>● Жижиг оврын бараа 3000₮-4000₮</div>
          <div>
            ● Овор ихтэй ууттай куртик, хөнжил гэх мэт зүйлс овор хэмжээнээсээ
            хамаараад ойролцоогоор 5000₮-10000₮ хооронд байдаг.
          </div>
          <div>
            ● Хайрцагтай бараа 5000₮-аас эхлэн 'Овор' болон 'Жин' хоёрын аль
            нэгээр бодогдоно.
          </div>
          {/* <div className="border p-2">
            <div className="font-semibold text-gray-800 py-2 uppercase">
              1м.куб -ээс бага ачааг дундажлах арга ашиглан бодно.
            </div>
            <div>
              <span className="font-semibold">ОВРИЙН ҮНЭ</span> = (Урт * Өргөн *
              Өндөр) / 1,000,000 * 360,000
            </div>
            <div>
              <span className="font-semibold">ЖИНГИЙН ҮНЭ</span> = Кг * 3,000
            </div>
            <div>
              <span className="font-semibold">БАРААНЫ ҮНЭ</span> = (ОВРИЙН ҮНЭ +
              ЖИНГИЙН ҮНЭ) / 2
            </div>
            <div className="flex items-center justify-center gap-2 flex-col md:flex-row py-2">
              <img
                src="/10.png"
                alt="1m3-down"
                className="object-cover w-full md:w-[48%]"
              />
              <img
                src="/20.png"
                alt="1m3-down"
                className="object-cover w-full md:w-[48%]"
              />
            </div>
            <div>
              Овор: (60 * 45 * 30) / 1,000,000 * 360,000 =
              <span className="font-semibold pl-1">29,160₮</span>
            </div>
            <div>
              Жин: 10кг * 3,000 = <span className="font-semibold">30,000₮</span>
            </div>
            <div>
              Жин: 20кг * 3,000 = <span className="font-semibold">60,000₮</span>
            </div>
            <div>
              Карго 10кг: (29,160 + 30,000) / 2 =
              <span className="font-semibold  pl-1">29,580₮</span>
            </div>
            <div>
              Карго 20кг: (29,160 + 60,000) / 2 =
              <span className="font-semibold  pl-1">44,580₮</span>
            </div>
          </div> */}
          <div className="border p-2">
            <div className="font-semibold text-gray-800 py-2 uppercase">
              ЖИНГЭЭР БОДОХ УУ?, ОВРООР БОДОХ УУ?
            </div>
            <img src="/cal.png" alt="1m3-up" />
            <div>
              Таны захиалсан бараа харьцангуй хүнд боловч жижиг хэмжээтэй бол
              жингээр тээврийн зардлыг тооцоолно. (Үүнийг төмөр туухайтай
              зүйрлэж ойлгож болно.) Хэрэв таны бараа хөнгөн боловч овор хэмжээ
              нь харьцангуй том байвал овор хэмжээгээр тээврийн зардлыг бодно.
              (Үүнийг хөнгөн унадаг дугуйтай зүйрлэж ойлгож болно.)
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(PriceModal);
