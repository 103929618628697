import React from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { ClearIcon } from "./Icons";

const Address = ({ address_modal, setAddress_modal }) => {
  const address = [
    { type: 1, name: "收货人:", value: "峰顺商贸" },
    { type: 1, name: "电话:", value: "13644984007" },
    {
      type: 1,
      name: "地址:",
      value: "内蒙古自治区,锡林郭勒盟,二连浩特市二连浩特市,社区建设管理局",
    },
    {
      type: 2,
      name: "详细地址:",
      value: "环宇商贸城11号楼17号 (энд өөрийн утас)",
    },
  ];
  return (
    <div>
      {/* modal */}
      <div
        className={`${
          address_modal ? "fixed" : "hidden"
        } w-full h-full left-0 top-0 z-10 bg-black/50`}
        onClick={() => {
          setAddress_modal((prev) => ({ ...prev, address_modal: false }));
        }}
      />
      <div
        className={`${
          address_modal ? "fixed" : "hidden"
        } z-20 top-[55%] md:top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 w-[95%] md:w-[700px] max-h-[80%] h-auto overflow-auto bg-white rounded animate-fade`}
      >
        <div className="flex items-center justify-between p-2 sticky top-0 bg-white border-b">
          <h1 className="font-semibold tracking-wide uppercase md:text-xl">
            (ЭРЭЭН-ЭРДЭНЭТ) Хаяг холбох
          </h1>
          <div
            onClick={() => {
              setAddress_modal((prev) => ({ ...prev, address_modal: false }));
            }}
            className="cursor-pointer"
          >
            <ClearIcon />
          </div>
        </div>

        <div className="w-full px-4 flex items-center flex-col">
          {address.map((el, index) => {
            return (
              <div
                className="flex items-center gap-2 py-1 w-full border-b"
                key={index}
              >
                <div className="text-nowrap min-w-[70px]">{el.name}</div>
                <div className="text-left w-full">{el.value}</div>
                {(index === 1 || index === 3) && (
                  <div
                    className="flex items-end justify-end min-w-[24px]"
                    onClick={() => {
                      if (el.type === 1) {
                        copy(
                          "收货人: 峰顺商贸\n电话：13644984007\n地址：内蒙古自治区,锡林郭勒盟,二连浩特市二连浩特市,社区建设管理局"
                        );
                        toast.success("Хаяг хуулагдлаа");
                      } else if (el.type === 2) {
                        copy("环宇商贸城11号楼17号");
                        toast.success("Хаяг хуулагдлаа");
                      }
                    }}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
          <iframe
            title="Facebook Video"
            src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Ferdenetcargo.mn%2Fvideos%2F380227028283159%2F&show_text=false&width=220&t=0"
            width={220}
            height={476}
            style={{ border: "none", overflow: "hidden" }}
            scrolling="no"
            frameBorder={0}
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </div>
      </div>
      {/* modal */}
    </div>
  );
};

export default React.memo(Address);
