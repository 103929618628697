import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageComponent = ({ alt, src, css, minsize, maxsize }) => {
  return (
    <LazyLoadImage
      alt={alt}
      effect="blur"
      src={`${src}_${minsize}x${minsize}Q80.jpg_.webp`}
      srcSet={`${src}_${maxsize}x${maxsize}Q80.jpg_.webp ${maxsize}w, ${src}_${minsize}x${minsize}Q80.jpg_.webp 50w`}
      maxsizes={`(max-width: ${maxsize}px) 50px, ${maxsize}px`}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = "./no_image.jpg";
      }}
      placeholderSrc="./no_image.jpg"
      className={css}
    />
  );
};

export default ImageComponent;
