import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "./context";

const Header = () => {
  const navigate = useNavigate();
  const { state } = useContext(Context);
  return (
    <div className="fixed top-0 left-0 w-full h-16 bg-white flex items-center justify-between px-4 z-50 border-b shadow-md">
      <div
        className="font-bold text-[#F55B00] text-xl cursor-pointer"
        onClick={() => navigate("/")}
      >
        ERDENET CARGO
      </div>
      <div
        className="hidden cursor-pointer"
        onClick={() => {
          if (state?.loggedIn) {
            navigate("/arrived");
          } else {
            navigate("/login");
          }
        }}
      >
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.5"
            cx={12}
            cy={9}
            r={3}
            stroke="#F55B00"
            strokeWidth="1.5"
          />
          <circle cx={12} cy={12} r={10} stroke="#F55B00" strokeWidth="1.5" />
          <path
            opacity="0.5"
            d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
            stroke="#F55B00"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default React.memo(Header);
