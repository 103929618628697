import React, { useContext, useEffect, useState } from "react";
import { get } from "./axios";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "./components/loader";
import AdminHeader from "./components/adminHeader";
import { Context } from "./context";
import { Link } from "react-router-dom";

const Rank = () => {
  const { state } = useContext(Context);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const Check = () => {
    setLoading(true);
    get("/cargo/rank_calc")
      .then((res) => {
        if (res.success) {
          toast.success(res.data);
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    get("/cargo/rank")
      .then((res) => {
        if (res.success) {
          setList(res?.data);
        } else {
          toast.warn(res.error);
        }
      })
      .catch((err) => {
        toast.warn(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!state?.loggedIn)
    return (
      <div className="w-screen h-screen flex items-center justify-center flex-col">
        <div className="text-white text-xl">Нэвтрэх шаардлагатай.</div>
        <Link
          to={"/login"}
          className="px-6 py-2 rounded-md bg-green-500 text-white mt-2"
        >
          Нэвтрэх
        </Link>
      </div>
    );

  return (
    <div className="relative pt-0">
      {loading && <Loader />}
      <AdminHeader />
      <div className="container mx-auto">
        <div className="flex gap-2 pb-2">
          <div
            className="px-4 py-2 bg-white rounded cursor-pointer"
            onClick={() => {
              Check();
            }}
          >
            Шалгах
          </div>
        </div>
        <DataTable
          value={list}
          size="small"
          className="text-sm sm:text-base"
          emptyMessage="Хоосон байна."
          header={<div>Үйлчлүүлэгчдийн цолны жагсаалт</div>}
        >
          <Column
            field="id"
            header="№"
            body={(data, row) => {
              return row?.rowIndex + 1;
            }}
          />
          <Column field="phone" header="Утас" sortable />
          <Column
            field="total_payment"
            header="Нийт үнэ"
            sortable
            body={(data) => parseInt(data?.total_payment)?.toLocaleString()}
          />
          <Column field="rank" header="Одоогийн цол" sortable />
          <Column field="next_rank" header="Дараагийн цол" sortable />
          <Column
            field="rank_percent"
            header="Дараагийн цолонд хүрэх хувь"
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};

export default React.memo(Rank);
