import axios from "axios";
import { toast } from "react-toastify";

const baseURL = "https://allrent.mn/api";
// const baseURL = "http://localhost:8000/api";

export function ALL_RENT() {
  return createInstance(baseURL);
}

const createInstance = (baseURL) => {
  let headers = {};
  let token =
    typeof window !== "undefined" ? localStorage.getItem("token") : null;
  if (token != null) {
    Object.assign(headers, {
      Authorization: "Bearer " + token,
    });
  }
  let api = axios.create({
    baseURL: baseURL,
    timeout: 200000,
    headers,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();
      } else if (error) {
        if (error?.message) {
          throw toast.warning(error?.message);
        } else {
          throw error;
        }
      }
    }
  );
  return api;
};

// Хэрэглэгчийн хувийн мэдээлэл

export async function post(url, json) {
  const response = await ALL_RENT().post(url, json);
  return response.data;
}
export async function put(url, json) {
  const response = await ALL_RENT().put(url, json);
  return response.data;
}
export async function upload(url, formData) {
  const response = await ALL_RENT().post(url, formData);
  return response.data;
}
export async function get(url) {
  const response = await ALL_RENT().get(url);
  return response.data;
}
export async function getparams(url, params) {
  const response = await ALL_RENT().get(url, { params: params });
  return response.data;
}
export async function postparams(url, params) {
  const response = await ALL_RENT().post(url, { params: params });
  return response.data;
}
export async function trash(url) {
  const response = await ALL_RENT().delete(url);
  return response.data;
}
